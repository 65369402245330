module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://lukbrt.vot.pl/graphql","baseUrl":"lukbrt.vot.pl","hostingWPCOM":false,"protocol":"https","useACF":true,"verboseOutput":true,"excludedRoutes":["**/contact-form-7/v1/**","**/newsletter/v1/**","**/users/me","**/settings","**/themes"],"includedRoutes":["**/categories","**/posts","**/pages","**/media","**/tags","**/taxonomies","**/users"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["pl","en"],"defaultLanguage":"pl","defaultLocale":"pl","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154781781-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    }]
